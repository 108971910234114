/* TokenIntroduction.css */
.container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  
  .overview-card {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .overview-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
  
  .icon.blue { color: #2563eb; }
  .icon.green { color: #059669; }
  .icon.orange { color: #f1aa4d; }
  .icon.red { color: #dc2626; }
  .icon.purple { color: #7c3aed; }
  
  .title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  .subtitle {
    font-size: 1.25rem;
    color: #4b5563;
    margin-bottom: 1rem;
  }
  
  .description {
    color: #4b5563;
  }
  
  .section {
    margin-bottom: 2rem;
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .consumption-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    max-width: 640px;
    margin: 0 auto;
  }
  
  .plans-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    max-width: 640px;
    margin: 0 auto;
  }
  
  .card {
    width: 300px;
    background-color: white;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .card-title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .plan-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .plan-title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-left: 0.5rem;
  }
  
  .token-count {
    font-size: 1.875rem;
    color: #2563eb;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .card-description {
    color: #4b5563;
    font-size: 0.875rem;
  }
  
  @media (max-width: 768px) {
    .consumption-grid {
      grid-template-columns: 1fr;
    }
  }